.App {
  text-align: center;
  background-color: rgb(19, 23, 44);
  height: 100vh;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  margin-bottom: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @media (max-width: 768px) {
  .App {
    height: 170vh;
  }
} */
